import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import {TOKEN,LOGIN_ROUTER} from '@/const/index.js';
import {getItem} from '@/utils/utils'

//PC端路由
const routerPC = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue")
  },
  { // 介绍页
      path: "/course",
      name: "Course",
      component: () => import("@/views/home/course.vue")
  },
  { // 学习页
      path: "/study",
      name: "Study",
      component: () => import("@/views/home/study.vue")
  },
  { // 我的课程
      path: "/personal",
      name: "Personal",
      component: () => import("@/views/home/personal.vue")
  },

    { // 关于我们
        path: "/about",
        name: "About",
        component: () => import("@/views/home/about.vue")
    },
    { // 联系我们
        path: "/contact",
        name: "Contact",
        component: () => import("@/views/home/contact.vue")
    },
    { // 404
        path: "/error",
        name: "Error",
        component: () => import("@/views/home/error.vue")
    },
    { // 课程列表
        path: "/list",
        name: "List",
        component: () => import("@/views/home/list.vue")
    },
// {
  //     path: "/login",
  //     name: "Login",
  //     component: () => import("@/views/home/login.vue")
  // }
  
];



const router = createRouter({
  // history: createWebHashHistory(), //hash为页面重置跳转首页
  history: createWebHistory(),        //history为页面不重置跳转首页
  routes:routerPC
})
router.beforeEach((to, from,nuxt)=>{
  //需要登录的路由
  if(getItem(TOKEN)){
    nuxt()
  }else{
    //跳转首页
    if(LOGIN_ROUTER.includes(to.path)){
      nuxt(from.path)
    }else{
      nuxt()
    }

  }
});
// router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g;
//   const isChunkLoadFailed = error.message.match(pattern);
//   const targetPath = router.history.pending.fullPath;
//   if (isChunkLoadFailed) {
//     router.replace(targetPath);
//   }
// });
export default router
